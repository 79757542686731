import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Grid, Box, Paper, Stack, Typography, Button } from "@mui/material"
import { useStaticQuery, graphql } from "gatsby"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"
import { StaticImage } from "gatsby-plugin-image"

const Fusionexplus = props => {
  const { goodsHeaderId, companyId, company, goodsList } = props
  const [goodsId, setGoodsId] = useState(0)
  const [btnDisable, setBtnDisable] = useState(true)

  const onChangeSelect = e => {
    setGoodsId(e.target.value)
  }

  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "top/fusion1.png" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
    }
  `)

  useEffect(() => {
    goodsId > 0 ? setBtnDisable(false) : setBtnDisable(true)
  }, [goodsId])
  return (
    <>
      <Box sx={{ marginTop: 3, marginLeft: 1, marginRight: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <h1>
              <StaticImage
                src="../assets/images/pc/logo.png"
                width={244}
                height={75}
                alt="フュージョンEXプラス"
                placeholder="blurred"
                quality="65" // 画質
              />
            </h1>
          </Grid>
          <Grid item xs={12} md={9}>
            <StaticImage
              src="../assets/images/pc/header01.png"
              width={662}
              height={50}
              alt={`お電話でのご注文・お問い合わせは${company.phone}`}
              placeholder="blurred"
              quality="65" // 画質
            />
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={3} sx={{ marginTop: 3, marginLeft: 1, marginRight: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 2,
                m: 2,
                // backgroundColor: "#F1F1EE",
                backgroundColor: "#ffffff",
              }}
            >
              <img
                style={{ width: "100%" }}
                src={data.img1.publicURL}
                alt="フュージョンEXプラス"
              />
              <FormControl sx={{ width: "100%", mt: 3 }}>
                <form method="post" action={process.env.CART_URL}>
                  <input type="hidden" name="q" value="1" />
                  <input type="hidden" name="m" value="1" />
                  <input type="hidden" name="h" value={goodsHeaderId} />
                  <input type="hidden" name="i" value={goodsId} />
                  <input type="hidden" name="company_id" value={companyId} />
                  <input
                    type="hidden"
                    name="back_url"
                    value="https://body-custom.net/fusionexplus"
                  />
                  <input
                    type="hidden"
                    name="deliver_trader_id"
                    value={process.env.TRADER_ID}
                  />
                  <InputLabel id="goods_id">お選びください</InputLabel>
                  <Select
                    labelId="goods_id_label"
                    id="goods_id"
                    label="お選びください"
                    required={true}
                    value={goodsId}
                    sx={{ width: "100%" }}
                    onChange={e => onChangeSelect(e)}
                  >
                    {goodsList.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label.replace("null", "")}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ textAlign: "right" }}>
                    バリエーションを選んでください
                  </FormHelperText>

                  <div className="text-center p-2">
                    <Button
                      type="submit"
                      variant="contained"
                      color="warning"
                      startIcon={<AddShoppingCartIcon />}
                      disabled={btnDisable}
                    >
                      購入へ
                    </Button>
                  </div>
                </form>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                m: 2,
                p: 2,
                backgroundColor: "#F1F1F1",
              }}
            >
              <Stack spacing={3}>
                <Typography variant="h4">フュージョンEXプラス</Typography>
                <Typography>
                  <span className="font-semibold">詳細</span>
                  ：シトルリン・アルギニンを独自の黄金バランス＆たっぷり大容量で配合したエナジーサプリメント。スポーツする方にはもちろん、毎日仕事に遊びにと頑張る方のためにオススメ。
                </Typography>
                <Typography>
                  <span className="font-semibold">送料</span>
                  ：2本セット以上のご注文で送料が無料になります。
                </Typography>
                <Typography>
                  <span className="font-semibold">内容量</span>：30粒
                </Typography>
                <Typography>
                  <span className="font-semibold">原材料</span>
                  ：L-シトルリン、マカ、クラチャイダム、ソフォン、黒胡椒抽出物、カンカ、ムクナ、サソリ、ウミヘビ、ガラナエキス、トナカイ、コブラ、亜鉛酵母、ロディオラ、海馬、豚睾丸エキス末、貝カルシウム、結晶セルロース、ゼラチン、L-アルギニン、カフェイン、ステアリン酸カルシウム、二酸化チタン、ビタミンC、抽出ビタミンE、ナイアシン、バントテン酸Ca、ビタミンB1、ビタミンB6、ビタミンB2、ビタミンA、葉酸、ビタミンD、ビタミンB12
                </Typography>
                <Typography>
                  <span className="font-semibold">召し上がり方</span>
                  ：1日1粒を目安に水などと一緒にお召し上がりください。
                </Typography>
                <Typography>
                  <span className="font-semibold">保存方法</span>
                  ：高温多湿、直射日光を避けて保管してください。
                </Typography>
              </Stack>
            </Box>
            <Typography textAlign="right">
              <Link to="/sc" className="mr-5">
                <Button
                  variant="outlined"
                  href="#outlined-buttons"
                  className="mb-5"
                >
                  トップページへ戻る
                </Button>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Fusionexplus
