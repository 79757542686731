import React, { useState, useEffect, memo } from "react"
import { Helmet } from "react-helmet"
import { fetchData } from "../libs/fetchers"
import { graphql } from "gatsby"
import Fusionexplus from "../components/sc/Fusionexplus"
import Footer from "../components/Footer"

const apiUrl = `${process.env.API_COMPANY_URL}?ref=fusionex`

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        copyright
      }
    }
  }
`
const TopFusionex = ({ data }) => {
  const [companyId, setCompanyId] = useState(1)
  const [goodsHeaderId, setGoodsHeaderId] = useState(5)
  const [company, setCompany] = useState({})
  const [goodsList, setGoodsList] = useState([])

  const getGoodsList = async goods_header_id => {
    const data = await fetchData("goods/getList.php", {
      goods_header_id: goods_header_id,
    })
    const newData = data.map(e => ({
      value: e["goods_id"],
      label:
        e["goods_name"] +
        e["color_name"] +
        " " +
        e["goods_price"] +
        "円（税込）",
    }))
    setGoodsList(newData)
    return newData
  }

  useEffect(() => {
    fetch(apiUrl)
      .then(x => x.json())
      .then(x => setCompany(x))

    getGoodsList(goodsHeaderId).then(glist => {
      console.log(glist)
    })
  }, [])

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja-jp" }}
        title={company.title}
        meta={[
          {
            name: `description`,
            content: company.title,
          },
        ]}
      />
      <main className="App">
        <Fusionexplus
          goodsHeaderId={goodsHeaderId}
          companyId={companyId}
          company={company}
          goodsList={goodsList}
        />
      </main>

      <Footer company={company} siteData={data.site.siteMetadata} />
    </>
  )
}

export default memo(TopFusionex)
